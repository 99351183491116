import React, { type FC, type ReactChild } from 'react';
import type { MessageDescriptor } from 'react-intl-next';
import { FormattedMessage } from 'react-intl-next';

import { token } from '@atlaskit/tokens';
import { Pressable, Inline, xcss } from '@atlaskit/primitives';
import WarningIcon from '@atlaskit/icon/glyph/warning';
import PremiumIcon from '@atlaskit/icon/glyph/premium';

type UserCountButtonBaseProps = {
	text: MessageDescriptor;
	icon?: ReactChild;
	userCountStyle?: 'high' | 'low';
	onClick: () => void;
};

type UserCountButtonProps = {
	text: MessageDescriptor;
	onClick: () => void;
};

const lowUserCounButtonStyles = xcss({
	backgroundColor: 'elevation.surface',
	borderColor: 'color.border.accent.blue',
	borderRadius: 'border.radius',
	borderWidth: 'border.width',
	borderStyle: 'solid',
	paddingLeft: 'space.150',
	paddingRight: 'space.150',
	paddingTop: 'space.075',
	paddingBottom: 'space.075',
	color: 'color.link',
	fontWeight: token('font.weight.medium'),
	fontStyle: 'normal',
	fontFamily: 'inherit',
	display: 'inline-flex',
	textAlign: 'center',
	':hover': {
		backgroundColor: 'elevation.surface.hovered',
	},
	':active': {
		backgroundColor: 'elevation.surface.pressed',
	},
});

const highUserCountButtonStyles = xcss({
	backgroundColor: 'elevation.surface',
	borderColor: 'color.border.accent.blue',
	borderRadius: 'border.radius',
	borderWidth: 'border.width',
	borderStyle: 'solid',
	paddingLeft: 'space.150',
	paddingRight: 'space.150',
	paddingTop: 'space.075',
	paddingBottom: 'space.075',
	color: 'color.link',
	fontWeight: token('font.weight.medium'),
	fontStyle: 'normal',
	fontFamily: 'inherit',
	display: 'inline-flex',
	textAlign: 'center',
	':hover': {
		backgroundColor: 'color.background.neutral.hovered',
	},
	':active': {
		backgroundColor: 'color.background.neutral.pressed',
	},
});

export const UserCountButton: FC<UserCountButtonBaseProps> = ({
	text,
	icon,
	userCountStyle,
	onClick,
}) => {
	return (
		<Pressable
			xcss={userCountStyle === 'high' ? highUserCountButtonStyles : lowUserCounButtonStyles}
			onClick={onClick}
		>
			<Inline space="space.075">
				{icon}
				<FormattedMessage {...text} />
			</Inline>
		</Pressable>
	);
};

export const LowUserCountButton: FC<UserCountButtonProps> = ({ text, onClick }) => {
	return (
		<UserCountButton
			icon={
				<PremiumIcon
					primaryColor={token('color.icon.accent.purple')}
					label="Premium"
					size="small"
				/>
			}
			text={text}
			userCountStyle="low"
			onClick={onClick}
		/>
	);
};

export const HighUserCountButton: FC<UserCountButtonProps> = ({ text, onClick }) => {
	return (
		<UserCountButton
			icon={<WarningIcon primaryColor={token('color.icon.warning')} label="Warning" size="small" />}
			text={text}
			userCountStyle="high"
			onClick={onClick}
		/>
	);
};
