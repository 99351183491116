import { type ConfluenceEdition } from '@confluence/session-data';

export const AUTO_UPGRADE_AWARENESS_SOURCE = 'autoUpgradeAwareness';

// AUA button
export const PERSISTENT_UPGRADE_FLOWID = 'upgradeFlow';
export const PERSISTENT_UPGRADE_TOUCHPOINT_BUTTON = 'persistent-upgrade-button';
export const PERSISTENT_UPGRADE_ANALYTICS_SOURCE = 'persistentUpgrade';
export const AUTO_UPGRADE_AWARENESS_FREE_PILL_TOUCHPOINT = 'autoUpgradeAwarenessFreePill';
export const AUTO_UPGRADE_AWARENESS_STANDARD_PILL_TOUCHPOINT = 'editionAwarenessStandardPill';
export const AUTO_UPGRADE_AWARENESS_PILL_LOW_USER_COUNT = 'lowCount';
export const AUTO_UPGRADE_AWARENESS_PILL_HIGH_USER_COUNT = 'highCount';

// AUA modal
export const AUTO_UPGRADE_AWARENESS_MODAL_SOURCE = 'autoUpgradeAwarenessModal';
export const AUTO_UPGRADE_AWARENESS_MODAL_TEST_ID = 'auto-upgrade-awareness-modal';

// AUA free modal
export const AUTO_UPGRADE_AWARENESS_FREE_1_2_MODAL = 'autoUpgradeAwarenessFreeModal1-2Users';
export const AUTO_UPGRADE_AWARENESS_FREE_3_9_MODAL = 'autoUpgradeAwarenessFreeModal3-9Users';
export const AUTO_UPGRADE_AWARENESS_FREE_10_MODAL = 'autoUpgradeAwarenessFreeModal10Users';

// AUA standard modal
export const AUTO_UPGRADE_AWARENESS_STANDARD_1_9_MODAL =
	'autoUpgradeAwarenessStandardTrialModal1-9Users';
export const AUTO_UPGRADE_AWARENESS_STANDARD_10_MODAL =
	'autoUpgradeAwarenessStandardTrialModal10Users';

// AUA modal storage component
export const AUTO_UPGRADE_AWARENESS_STORAGE_USAGE_COMPONENT = 'autoUpgradeAwarenessStorageUsage';

export const getModalEventName = (userCount: number, edition: ConfluenceEdition | null) => {
	if (edition === 'FREE') {
		if (userCount >= 10) {
			return AUTO_UPGRADE_AWARENESS_FREE_10_MODAL;
		} else if (userCount >= 3) {
			return AUTO_UPGRADE_AWARENESS_FREE_3_9_MODAL;
		} else {
			return AUTO_UPGRADE_AWARENESS_FREE_1_2_MODAL;
		}
	} else {
		if (userCount >= 10) {
			return AUTO_UPGRADE_AWARENESS_STANDARD_10_MODAL;
		} else {
			return AUTO_UPGRADE_AWARENESS_STANDARD_1_9_MODAL;
		}
	}
};
