import { useCallback } from 'react';

import { useAnalyticsEvents } from '@atlaskit/analytics-next';

import { useSessionData } from '@confluence/session-data';

import {
	AUTO_UPGRADE_AWARENESS_PILL_LOW_USER_COUNT,
	AUTO_UPGRADE_AWARENESS_PILL_HIGH_USER_COUNT,
	PERSISTENT_UPGRADE_TOUCHPOINT_BUTTON,
	PERSISTENT_UPGRADE_FLOWID,
	PERSISTENT_UPGRADE_ANALYTICS_SOURCE,
} from './analyticsConstants';

type ButtonEventHandler = {
	action: 'viewed' | 'clicked';
	isEligibleForMpu?: boolean;
	mpuAnalyticsAttributes?: {
		isBillingAdmin?: boolean;
		isSiteAdmin?: boolean;
	};
	userCount: number;
	auaAnalyticAttributes?: { autoUpgradeAwarenessExperimentCohort?: string };
};

export const useButtonEventHandler = () => {
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const { edition } = useSessionData();

	const eventHandler = useCallback(
		({
			action,
			mpuAnalyticsAttributes,
			isEligibleForMpu,
			auaAnalyticAttributes,
			userCount,
		}: ButtonEventHandler) => {
			createAnalyticsEvent({
				type: 'sendUIEvent',
				data: {
					action,
					actionSubject: PERSISTENT_UPGRADE_TOUCHPOINT_BUTTON,
					source: PERSISTENT_UPGRADE_ANALYTICS_SOURCE,
					attributes: {
						currentEdition: edition,
						touchpointId: PERSISTENT_UPGRADE_TOUCHPOINT_BUTTON,
						growthFeature: PERSISTENT_UPGRADE_ANALYTICS_SOURCE,
						flowId: PERSISTENT_UPGRADE_FLOWID,
						userCount,
						buttonType:
							userCount <= 2
								? AUTO_UPGRADE_AWARENESS_PILL_LOW_USER_COUNT
								: AUTO_UPGRADE_AWARENESS_PILL_HIGH_USER_COUNT,
						...(isEligibleForMpu && mpuAnalyticsAttributes),
						...auaAnalyticAttributes,
					},
				},
			}).fire();
		},
		[createAnalyticsEvent, edition],
	);

	return { eventHandler };
};
