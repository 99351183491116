import { useQuery } from '@apollo/react-hooks';

import { isErrorMarkedAsHandled, markErrorAsHandled } from '@confluence/graphql-error-processor';

import { NumberOfUsersInvitedQuery } from '../graphql/NumberOfUsersInvited.graphql';

export const useGetUserCount = () => {
	const { data, error, loading } = useQuery(NumberOfUsersInvitedQuery, {
		fetchPolicy: 'cache-and-network',
	});

	if (error && !isErrorMarkedAsHandled(error)) {
		markErrorAsHandled(error);
	}

	return {
		error,
		loading,
		userCount: data?.license?.licenseConsumingUserCount,
	};
};
