import gql from 'graphql-tag';

export const CanProductUserAddBilling = gql`
	query CanProductUserAddBilling($cloudId: ID!, $productKey: String!) {
		tenantContexts(cloudIds: [$cloudId])
			@optIn(to: ["CcpAllUserUpgradeAndPay", "HamsAllUserUpgradeAndPay"]) {
			entitlementInfo(hamsProductKey: $productKey) {
				entitlement(where: { AND: [{ inTrialOrPreDunning: true }] }) {
					transactionAccount {
						isCurrentUserBillingAdmin
					}
					subscription {
						accountDetails {
							invoiceGroup {
								invoiceable
								experienceCapabilities {
									configurePaymentV2 {
										experienceUrl
										isAvailableToUser
									}
								}
							}
						}
					}
				}
			}
		}
	}
`;
