import React, { useCallback, useContext } from 'react';

import { useAnalyticsEvents } from '@atlaskit/analytics-next';

import type { UpFlowContextType } from '@confluence/change-edition';
import { ConfluenceEdition, UpFlowContext } from '@confluence/change-edition';
import { LoadableLazy } from '@confluence/loadable';
import { useCanUpgradeAndPayForProductUser } from '@confluence/experiment-more-product-users-can-upgrade-pay';
import { useAutoUpgradeAwareness } from '@confluence/experiment-auto-upgrade-awareness';

import {
	PERSISTENT_UPGRADE_FLOWID,
	PERSISTENT_UPGRADE_TOUCHPOINT_BANNER,
	PERSISTENT_UPGRADE_TOUCHPOINT_BUTTON,
} from './constants';
import { constructUIEvent } from './constructPersistentUpgradeAnalyticsEvents';
import { useTrialPlanComparisonModalActions } from './TrialPlanComparisonExperiment/useTrialPlanComparisonModal';

const LazyExperimentPlanSelectionModal = LoadableLazy({
	loader: async () =>
		(
			await import(
				/* webpackChunkName: "loadable-ExperimentPlanSelectionModal" */ './TrialPlanComparisonExperiment/ExperimentPlanSelectionModal'
			)
		).ExperimentPlanSelectionModal,
});

export const useUpflowModal = (touchpointId?) => {
	const { showModal: showTrialComparisonModal } = useTrialPlanComparisonModalActions();
	const { analyticsAttributes: mpuAnalyticsAttributes, canUpgradeAndPayForProductUser } =
		useCanUpgradeAndPayForProductUser();
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const { currentEdition } = useContext<UpFlowContextType>(UpFlowContext);
	const { auaAnalyticAttributes } = useAutoUpgradeAwareness();

	const openUpflowModal = useCallback(() => {
		const fireUIEvent = (eventAction: string) => {
			createAnalyticsEvent(
				constructUIEvent({
					eventAction,
					touchpointSubject:
						touchpointId === PERSISTENT_UPGRADE_TOUCHPOINT_BUTTON
							? PERSISTENT_UPGRADE_TOUCHPOINT_BUTTON
							: PERSISTENT_UPGRADE_TOUCHPOINT_BANNER,
					currentEdition,
					touchpointId,
					flowId: PERSISTENT_UPGRADE_FLOWID,
					experimentAttributes: {
						...(canUpgradeAndPayForProductUser && mpuAnalyticsAttributes),
						...auaAnalyticAttributes,
					},
				}),
			).fire();
		};

		fireUIEvent('clicked');

		void showTrialComparisonModal({ touchpointId });
	}, [
		showTrialComparisonModal,
		touchpointId,
		createAnalyticsEvent,
		canUpgradeAndPayForProductUser,
		mpuAnalyticsAttributes,
		auaAnalyticAttributes,
		currentEdition,
	]);

	const UpflowModalMountPoint = () => {
		return <>{currentEdition === ConfluenceEdition.FREE && <LazyExperimentPlanSelectionModal />}</>;
	};

	return {
		openUpflowModal,
		UpflowModalMountPoint,
	};
};
