import type { Action } from 'react-sweet-state';
import { createHook, createStore, createActionsHook } from 'react-sweet-state';
interface DialogState {
	isOpen: boolean;
	touchpointId?: string;
}

const initialState: DialogState = { isOpen: false };
const actions = {
	showModal:
		({ touchpointId }): Action<DialogState> =>
		({ setState }) => {
			setState({ isOpen: true, touchpointId });
		},
	closeModal:
		(): Action<DialogState> =>
		({ setState }) => {
			setState({ isOpen: false });
		},
};

const store = createStore({
	initialState,
	actions,
	name: 'trialPlanComparisonModalStore',
});

export const useTrialPlanComparisonModalActions = createActionsHook(store);

export const useTrialPlanComparisonModal = createHook(store);
