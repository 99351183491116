/* eslint-disable check-file/filename-blocklist */
export const CONFLUENCE_TRIAL_LENGTH_14 = 14;

export enum PERSISTENT_UPGRADE_EDITION_TRIAL_TYPES {
	NOT_SUPPORTED = 'not-supported',
	PRE_TRIAL = 'pre-trial',
}

export const PERSISTENT_UPGRADE_TOUCHPOINT_BANNER = 'persistentUpgradeBanner';

export const PERSISTENT_UPGRADE_ANALYTICS_SOURCE = 'persistentUpgrade';
export const PERSISTENT_UPGRADE_FLOW_UPGRADE = 'persistent-upgrade';
export const PERSISTENT_UPGRADE_FLOWID = 'upgradeFlow';
export const PERSISTENT_UPGRADE_TOUCHPOINT_BUTTON = 'persistent-upgrade-button';
