import { useQuery } from '@apollo/react-hooks';
import { useContext, useMemo } from 'react';
import type { ApolloError } from 'apollo-client';

import { useAnalyticsEvents } from '@atlaskit/analytics-next';

import { ConfluenceEdition } from '@confluence/change-edition/entry-points/ConfluenceEdition';
import { getAGGClient, isErrorMarkedAsHandled, markErrorAsHandled } from '@confluence/graphql';
import { AccessStatus, useSessionData } from '@confluence/session-data';
import { SPAViewContext } from '@confluence/spa-view-context';

import { TrialDetailsQuery } from '../graphql/TrialDetailsQuery.graphql';
import type { TrialDetailsQuery as TrialDetailsQueryType } from '../graphql/__types__/TrialDetailsQuery';
import { CanProductUserAddBilling } from '../graphql/CanProductUserAddBilling.agggraphql';

import { MPU_QUERY } from './mpuHookConstants';

export const useCanAddBillingForTrialProductUser = (skip: boolean = false) => {
	const { isSiteAdmin, isAnonymous } = useContext(SPAViewContext);
	const { cloudId, edition, isLoggedIn, accessStatus } = useSessionData();
	const { createAnalyticsEvent } = useAnalyticsEvents();

	const meetsBaseEligibility =
		!isSiteAdmin &&
		!isAnonymous &&
		isLoggedIn &&
		(accessStatus === AccessStatus.LICENSED_ADMIN_ACCESS ||
			accessStatus === AccessStatus.LICENSED_USE_ACCESS) &&
		(edition === ConfluenceEdition.STANDARD || edition === ConfluenceEdition.PREMIUM) &&
		!skip;

	// 1. determine billing system (both control & variation need to make the Commerce request)
	const {
		data: { license } = { license: null },
		error: trialDetailsError,
		loading: trialDetailsLoading,
	} = useQuery<TrialDetailsQueryType>(TrialDetailsQuery, {
		skip: !meetsBaseEligibility,
	});

	// 2. if CCP, make commerce query and determine if they meet backend eligibility
	const {
		data: commerceData,
		error: commerceError,
		loading: commerceLoading,
	} = useQuery(CanProductUserAddBilling, {
		client: getAGGClient(),
		variables: {
			cloudId,
			productKey: MPU_QUERY.HAMS_PRODUCT_KEY,
		},
		skip: !meetsBaseEligibility || license?.billingSourceSystem !== 'CCP',
	});

	const errors = [trialDetailsError, commerceError];

	errors
		.filter((error?: ApolloError): error is ApolloError => !!error)
		.forEach((error: ApolloError) => {
			!isErrorMarkedAsHandled(error) && markErrorAsHandled(error);
		});

	if (commerceError) {
		createAnalyticsEvent({
			type: 'sendOperationalEvent',
			data: {
				action: 'failed',
				actionSubject: 'canProductUserPay',
				source: 'useCanAddBillingForTrialProductUser',
				attributes: {
					edition,
					isSiteAdmin,
				},
			},
		}).fire();
	}
	const { isBillingAdmin, canAddBillingDetails, hasBillingDetails } =
		parseCommerceResponse(commerceData);

	const canAddBillingForTrial =
		meetsBaseEligibility &&
		license?.billingSourceSystem === 'CCP' &&
		canAddBillingDetails &&
		!hasBillingDetails;

	const analyticsAttributes = useMemo(
		() => ({
			isSiteAdmin,
			isBillingAdmin: canAddBillingForTrial ? isBillingAdmin : undefined,
		}),
		[isSiteAdmin, canAddBillingForTrial, isBillingAdmin],
	);

	return {
		loading: commerceLoading || trialDetailsLoading,
		canAddBillingForTrial,
		// canAddBillingDetails lets us know that the site is either in trial OR pre-dunning
		//   Then a null trialEndDate means pre-dunning
		isPreDunning: canAddBillingDetails && license?.trialEndDate === null,
		isBillingAdmin,
		hasBillingDetails,
		analyticsAttributes,
		commerceError,
	};
};

const parseCommerceResponse = (data: any) => {
	const entitlement = data?.tenantContexts?.[0]?.entitlementInfo?.entitlement;
	const transactionAccount = entitlement?.transactionAccount;
	const invoiceGroup = entitlement?.subscription?.accountDetails?.invoiceGroup;
	const configurePayment = invoiceGroup?.experienceCapabilities?.configurePaymentV2;

	return {
		isBillingAdmin: !!transactionAccount?.isCurrentUserBillingAdmin,
		canAddBillingDetails: !!configurePayment?.isAvailableToUser,
		hasBillingDetails: invoiceGroup?.invoiceable,
	};
};
