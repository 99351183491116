import { useQuery } from 'react-apollo';
import { useContext } from 'react';

import { useAnalyticsEvents } from '@atlaskit/analytics-next';

import { isErrorMarkedAsHandled, markErrorAsHandled } from '@confluence/graphql';
import { useSessionData } from '@confluence/session-data';
import { SPAViewContext } from '@confluence/spa-view-context';

import { BillingSourceSystemQuery } from '../graphql/BillingSourceSystemQuery.graphql';

export const useBillingSourceSystem = (skip?: boolean) => {
	const { isSiteAdmin } = useContext(SPAViewContext);
	const { edition } = useSessionData();

	const { createAnalyticsEvent } = useAnalyticsEvents();

	const { data, error, loading } = useQuery(BillingSourceSystemQuery, {
		fetchPolicy: 'cache-first',
		skip,
	});

	if (error && !loading) {
		createAnalyticsEvent({
			type: 'sendOperationalEvent',
			data: {
				action: 'failed',
				actionSubject: 'canProductUserPay',
				source: 'useBillingSourceSystem',
				attributes: {
					isSiteAdmin,
					edition,
					error: error.message,
				},
			},
		}).fire();

		if (!isErrorMarkedAsHandled(error)) {
			markErrorAsHandled(error);
		}
	}

	return {
		billingSourceSystem: data?.license?.billingSourceSystem,
		loading,
	};
};
