import gql from 'graphql-tag';

export const FetchPaymentAndUpgradeStatusForProductUser = gql`
	query FetchPaymentAndUpgradeStatusForProductUser(
		$cloudId: ID!
		$offeringKey: ID
		$productKey: String!
	) {
		tenantContexts(cloudIds: [$cloudId])
			@optIn(to: ["CcpAllUserUpgradeAndPay", "HamsAllUserUpgradeAndPay"]) {
			entitlementInfo(hamsProductKey: $productKey) {
				entitlementId
				entitlement {
					transactionAccount {
						isCurrentUserBillingAdmin
						experienceCapabilities {
							addPaymentMethodV2 {
								experienceUrl
								isAvailableToUser
							}
						}
					}
					experienceCapabilities {
						changeOfferingV2(offeringKey: $offeringKey) {
							experienceUrl
							isAvailableToUser
						}
					}
				}
			}
		}
	}
`;
