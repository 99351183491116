import { useContext } from 'react';

import { SPAViewContext } from '@confluence/spa-view-context';
import {
	useCanUpgradeAndPayForProductUser,
	useCanAddBillingForTrialProductUser,
} from '@confluence/experiment-more-product-users-can-upgrade-pay';

import { type AUAExperimentCohort } from '../experiment-auto-upgrade-awareness-types';
import { UNSAFE_noExposureExp } from '@confluence/feature-experiments';

export const useAutoUpgradeAwareness = () => {
	const { isSiteAdmin } = useContext(SPAViewContext);

	const { canUpgradeAndPayForProductUser: isEligibleForMpuFree } =
		useCanUpgradeAndPayForProductUser(isSiteAdmin);
	const { canAddBillingForTrial: isEligibleForMpuTrial } =
		useCanAddBillingForTrialProductUser(isSiteAdmin);

	const isEligible = isSiteAdmin || isEligibleForMpuFree || isEligibleForMpuTrial;

	if (!isEligible) {
		return {
			isAUAVariantEnabled: false,
			auaAnalyticAttributes: {},
		};
	}

	const [experimentConfig] = UNSAFE_noExposureExp('confluence_auto_upgrade_awareness');

	const cohort = experimentConfig.get<AUAExperimentCohort>(
		'cohort',
		'not-enrolled',
	) as AUAExperimentCohort;
	const isAAEnabled = experimentConfig.get<boolean>('isAAEnabled', false);
	const isABEnabled = experimentConfig.get<boolean>('isABEnabled', false);

	const isAUAVariantEnabled = cohort === 'test' && isABEnabled && isEligible;

	const auaAnalyticAttributes =
		(isAAEnabled || isABEnabled) && cohort !== 'not-enrolled'
			? { autoUpgradeAwarenessExperimentCohort: cohort, isAAEnabled, isABEnabled }
			: {};

	return {
		auaAnalyticAttributes,
		isAUAVariantEnabled,
	};
};
