import { useContext } from 'react';

import { ConfluenceEdition } from '@confluence/change-edition/entry-points/ConfluenceEdition';
import { SPAViewContext } from '@confluence/spa-view-context';
import { useSessionData } from '@confluence/session-data';
import { useCanUpgradeAndPayForProductUser } from '@confluence/experiment-more-product-users-can-upgrade-pay';

export const usePersistentUpgradeButton = () => {
	const { isSiteAdmin } = useContext(SPAViewContext);

	const { canUpgradeAndPayForProductUser } = useCanUpgradeAndPayForProductUser();

	const { edition } = useSessionData();

	const isEligible =
		(edition === ConfluenceEdition.FREE && isSiteAdmin) || canUpgradeAndPayForProductUser;

	return {
		isPersistentUpgradeButtonEligible: isEligible,
	};
};
