import gql from 'graphql-tag';

// When the customer starts an instance using HAMS, the exact timestamp of the trial
// end date is not recorded. We made the decision to assume the UTC date.
export const TrialDetailsQuery = gql`
	query TrialDetailsQuery {
		license {
			billingSourceSystem
			licenseConsumingUserCount
			trialEndDate
		}
	}
`;
